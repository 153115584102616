<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M28.0217 12.6538C28.2388 12.4367 28.5332 12.3148 28.8401 12.3148C29.1471 12.3148 29.4415 12.4367 29.6585 12.6538L36.2057 19.2009C36.3132 19.3084 36.3984 19.436 36.4566 19.5764C36.5147 19.7169 36.5447 19.8674 36.5447 20.0193C36.5447 20.1713 36.5147 20.3218 36.4566 20.4623C36.3984 20.6027 36.3132 20.7303 36.2057 20.8377L28.8408 28.2026L20.6569 20.0186L28.0217 12.6538ZM18.165 19.2369L10.017 27.3849C9.69455 27.7073 9.4388 28.0901 9.2643 28.5114C9.08981 28.9326 9 29.3841 9 29.8401C9 30.2961 9.08981 30.7476 9.2643 31.1689C9.4388 31.5901 9.69455 31.9729 10.017 32.2953L15.3827 37.661C15.5997 37.8781 15.8941 38 16.2011 38H37.024C37.6632 38 38.1814 37.4818 38.1814 36.8426C38.1814 36.2034 37.6632 35.6852 37.024 35.6852H24.6318L37.8425 22.4745C38.1649 22.1521 38.4207 21.7693 38.5952 21.3481C38.7697 20.9268 38.8595 20.4753 38.8595 20.0193C38.8595 19.5634 38.7697 19.1119 38.5952 18.6906C38.4207 18.2693 38.1649 17.8866 37.8425 17.5642L31.2953 11.017C30.6441 10.3658 29.761 10 28.8401 10C27.9192 10 27.0361 10.3658 26.3849 11.017L18.2384 19.1635C18.2257 19.1752 18.2132 19.1871 18.2009 19.1994C18.1886 19.2117 18.1766 19.2242 18.165 19.2369ZM19.0201 21.6554L27.204 29.8394L21.3582 35.6852H16.6805L11.6538 30.6585C11.5463 30.551 11.461 30.4235 11.4029 30.283C11.3447 30.1426 11.3148 29.9921 11.3148 29.8401C11.3148 29.6881 11.3447 29.5376 11.4029 29.3972C11.461 29.2568 11.5463 29.1292 11.6538 29.0217L19.0201 21.6554Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-eraser-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
